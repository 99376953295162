import {useState} from 'react';
import {} from 'date-fns';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {ShiftFormValues} from '../JobPosting/ShiftFormValues';
import {Config} from '../config';
import { fixNumber } from "../utils";
import * as moment from 'moment';
import {
  isSafari,
  isIOS,
  isMobile
} from "react-device-detect";


export function WageExcludedCostBreakDown(props: {isInModal: boolean, values: ShiftFormValues, isOpen: boolean, customLimberFee?, categoryLimitConfig?}) {
  const [open, setOpen] = useState( props.isOpen);

  // meed tp fix this for multi shifts
  let durationInHours = props.values.getTotalDurationHours();
  const wageCost = fixNumber(durationInHours * parseInt(props.values.workers) * props.values.hourlyRate);
  const holidayPayCost = fixNumber(wageCost * (Config.holidayPayRate));

  const nationalInsuranceRate = Config.getNationalInsuranceRate();
  const niCost = fixNumber((wageCost + holidayPayCost) * (nationalInsuranceRate));
  const limberFeeCost = fixNumber((wageCost + holidayPayCost + niCost) * (Config.feeRate));
  const totalExVatCost = fixNumber(wageCost + holidayPayCost + niCost + limberFeeCost);
  const vatCost = fixNumber(totalExVatCost * (Config.vatRate));
  const totalCost = fixNumber(totalExVatCost + vatCost);
  const valid = !Number.isNaN(totalCost);
  // add new line wages number of hours @ cost per hour
  if(Number.isNaN(durationInHours)){
    durationInHours = 0;
  }
  let wageBreakDown = fixNumber(durationInHours) +" hrs @ £"+ props.values.hourlyRate + " ph";

  return (
    <div className={`cost-breakdown ${props.isInModal ? "in-modal" : "in-form"} text-left`}>
      <div className="cost-breakdown-summary" onClick={() => valid && setOpen(!open)}>
        <span>
          {!isMobile &&
            <>This shift will cost you {props.values.isVariableRate ? "Max": ""}</>
          }
             <strong>{currency(limberFeeCost)}</strong> (excl. VAT) + <strong>cost of employment*</strong>
        </span>

        {valid && (
          <div className="icon">
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </div>
        )}
      </div>
        {!open && (
            <span className='cost-note'>
                *100% of the costs go to the workers and HMRC, and all payments are required when hiring through traditional methods
            </span>
        )}
        {(open) && (
            <div className="cost-breakdown-contents mt2 text-left">
          <dl>
            <dt>Wage costs ({wageBreakDown})</dt>
            <dd> {currency(wageCost)}</dd>
          </dl>
            <dl>
                <dt>Statutory holiday pay (@ {Config.holidayPayRate * 100}%)</dt>
                <dd>{currency(holidayPayCost)}</dd>
            </dl>
            <dl>
                <dt>Employer NI and pension (@ {Math.round(nationalInsuranceRate * 100)}%)</dt>
                <dd>{currency(niCost)}</dd>
            </dl>
            <dl>
                <dt>Limber fee (@ {Config.feeRate * 100}%)</dt>
                <dd>{currency(limberFeeCost)}</dd>
            </dl>
            <div className="mt2"></div>
            <dl>
                <dt>Total</dt>
                <dd>{currency(totalExVatCost)}</dd>
            </dl>
            <dl>
                <dt>VAT</dt>
                <dd>{currency(vatCost)}</dd>
            </dl>
            <dl className="total">
                <dt>Total inc. VAT</dt>
                <dd>{currency(totalCost)}</dd>
            </dl>
            <span className='cost-note'>
                *100% of the costs go to the workers and HMRC, and all payments are required when hiring through traditional methods
            </span>
            <div className="learn-more">
                <a href={Config.pricingPageUrl} target="_blank">
                Learn more about pricing
                </a>
            </div>
            </div>
        )}
    </div>
  );
}


export function currency(number: number): string {
  if (!isIOS && !isSafari) {
    return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(number);
  } else {
    return `£${number}`;
  }
}
