import * as React from 'react';
import {ShiftFormValues} from '../JobPosting/ShiftFormValues';
import format from 'date-fns/format';
import {WageExcludedCostBreakDown} from '../Summary/WageExcludedCostBreakDown';

import {CostBreakdown, currency} from './CostBreakdown';
import { ShiftSummary } from './ShiftSummary';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {useState} from 'react';

export function JobSummary(props: {values: ShiftFormValues, title: string}) {
    const type = props.values.type;
    const [open, setOpen] = useState( false);
    let endValue: string = '';
    let startValue: string = '';
    let location: string = '';
    location =  props.values.googlePlace.name +"" + props.values.googlePlace.address_components[0].long_name + " " + props.values.googlePlace.address_components[1].long_name + ", " + props.values.googlePlace.address_components[2].long_name
    if(type === 'stint'){
      endValue = format(new Date(props.values.endDate),"dd MMMM yyyy") ;
      startValue = format(new Date(props.values.startDate),"dd MMMM yyyy");
    }
    let showAdditionalShifts = type === 'shift'  && props.values.shifts.length > 1;
    return (
   <div className="hirer-onboarding-form confirm-modal">
          {/* turn this into a component */}
      <h2 className="mt2 text-center">
          <strong>
          {props.title}
          </strong>
        </h2>
        <div className="cost-breakdown-contents job-summary text-left mt2">
          <dl>
            <dt>Location:</dt>
            <dd>{location}</dd>
          </dl>
          <dl>
            <dt>Category:</dt>
            <dd>{props.values.category}</dd>
          </dl>
          <dl>
            <dt>No. of staff:</dt>
            <dd>{props.values.workers}</dd>
          </dl>
          <dl>
            <dt>Hourly rate:</dt>
            <dd>{currency(props.values.hourlyRate)}</dd>
          </dl>
          {type === 'stint' && (
            <>
              <dl>
                <dt>Start Date:</dt>
                <dd>{startValue}</dd>
              </dl>
              <dl>
                <dt>End Date:</dt>
                <dd>{endValue}</dd>
              </dl>
            </>
          )}{type === 'shift' && props.values.shifts.length > 0 && (
            <>
            <ShiftSummary startDate={props.values.shifts[0].startDate} endTime={props.values.shifts[0].endTime} startTime={props.values.shifts[0].startTime}/>
            </>
          )}
          {showAdditionalShifts &&(
            <div className="shift-breakdown-summary" onClick={() =>  setOpen(!open)}>
            <span>Additional Shifts</span>
              <div className="icon">
                <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
              </div>
            </div>
          )}    
          {showAdditionalShifts && open && props.values.shifts.map((shift,index)=>(
            index > 0 && (
              <ShiftSummary startDate={shift.startDate} endTime={shift.endTime} startTime={shift.startTime}/>
            )
          ))}
        </div>
        <WageExcludedCostBreakDown isInModal={true} values={props.values} isOpen={false} />
        {/* <CostBreakdown values={props.values} isOpen={false} /> */}
      </div>
    )

}